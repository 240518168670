import { Box } from "@mui/material"
import { useState } from "react"
import AddToBaseModal from "../../components/popups/addToBaseModal"
import ProfileEventsCheckbox from "../../components/Profile_events_checkbox"
import { EventsCategoryActions } from "../../hooks/profile_page_hooks/useProfileEventFieldData"
import { usePromotionEvents } from "../../shared/user-profile/hooks"
import InvitedEventList from "./inivited_event_list"
import MyEventList from "./my_event_list"
import { ProfileDetails } from "./profileDetails"
import { profileEventCards } from "./profileStyles"
import PromotionEventList from "./promotion_event_list"
import s from "./styles.module.css"
import WillGoEventList from "./will_go_event_list"
import { Outlet } from "react-router-dom"

const Profile = () => {
  const [dataType, setDataType] = useState<EventsCategoryActions>("myEvents")

  const typeDataSet = (evtType: EventsCategoryActions) => {
    setDataType((prev) => (prev = evtType))
  }

  return (
    <div className={s.main}>
      <ProfileDetails currentEvtType={dataType} setEventType={typeDataSet} />
      <AddToBaseModal/>
      <Box sx={{ width: "100%" }}>
        <ProfileEventsCheckbox onSetEventType={typeDataSet} chekedType={dataType} />
      </Box> 
      <Box sx={profileEventCards}>
        {/* место для карточек с эвентами */}
      <Outlet/>
      </Box> 
    </div>
  )
}

export default Profile
