import { Avatar, Box, Typography } from "@mui/material"
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks"
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  MutationDefinition,
} from "@reduxjs/toolkit/query"
import { useEffect, useRef, useState } from "react"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { Employees } from "../../../app/data/promotion/promotionApi"
import Pencil from "../../../assets/svg/promotion-icon/pencil"
import "../../../index.css"
import * as st from "./style"
import { EmployeeCardInfo } from "./EmployeeCardInfo"

type Props = {
  employees: Employees[]
  deleteUser: MutationTrigger<
    MutationDefinition<
      number,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
      string,
      void,
      "api"
    >
  >
}

export default function EmployeeCard({ employees, deleteUser }: Props) {
  const [animation, setAnimation] = useState(false)
  const [animateOnLoad, setAnimateOnLoad] = useState(false)
  const nodeRef = useRef<HTMLDivElement | null>(null)

  const handleDeleteUser = (id: number) => {
    deleteUser(id).then(() => {
      setAnimation(true)
    })
  }

  useEffect(() => {
    setAnimateOnLoad(true)
    setAnimation(true)
  }, [])

  return (
    <CSSTransition
      timeout={300}
      in={animateOnLoad}
      nodeRef={nodeRef}
      classNames="appearance"
      mountOnEnter
      unmountOnExit
    >
      <Box sx={st.employeesWrap} ref={nodeRef}>
        <Box sx={st.employeesCont}>
          <TransitionGroup component={null}>
            {employees.map((user) => {
              const { avatar_url, firstname, id, lastname, username } = user

              return (
                <CSSTransition
                  key={id}
                  in={animation}
                  classNames="appearance"
                  mountOnEnter
                  timeout={300}
                >
                  <EmployeeCardInfo
                    key={id}
                    handleDeleteUser={handleDeleteUser}
                    id={id}
                    avatar_url={avatar_url}
                    firstname={firstname}
                    lastname={lastname}
                    username={username}
                  />
                </CSSTransition>
              )
            })}
          </TransitionGroup>
        </Box>
      </Box>
    </CSSTransition>
  )
}
