import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Paper from "@mui/material/Paper"
import TextField from "@mui/material/TextField"
import { debounce } from "lodash"
import { useEffect, useState } from "react"
import SearchIcon from "../../../assets/svg/filter-pack/search-icon.svg?react"

import { useUpdateSearchParam } from "../../../hooks/useUpdateSearchParam"
import s from "../styles.module.css"

const FilterByCity = () => {
  const { setParam, currentParams } = useUpdateSearchParam("city")
  const [value, setValue] = useState<string | null>(currentParams[0])
  const [suggestCity, setSuggestCity] = useState<string | null>(null)
  const debouncedSetParam = debounce(setParam, 3000)

  useEffect(() => {
    return () => {
      debouncedSetParam.cancel()
    }
  }, [])

  useEffect(() => {
    if (value) {
      debouncedSetParam(value)
    } else {
      setValue(null)
      debouncedSetParam(undefined)
    }
  }, [value])

  return (
    <div className={s.category_box}>
      <h4 className={s.title}>Где искать:</h4>

      <div className={s.box}>
        <Autocomplete
          freeSolo
          autoHighlight={false}
          value={suggestCity}
          onChange={(_: any, newValue: string | null) =>
            setSuggestCity(newValue)
          }
          inputValue={value || ""}
          onInputChange={(_, value) => setValue(value)}
          id="autocomplete-field"
          options={[]}
          // options={cities.map((c) => c)}
          renderOption={(props, option, { selected }) => (
            <Box component="div" key={option}>
              <li {...props}>{option}</li>
              <Divider />
            </Box>
          )}
          PaperComponent={({ children }) => (
            <Paper
              sx={{
                border: "none",
                borderRadius: "8px",
                mt: 1,
                bgcolor: "var(--main-bg)",

                "& .MuiAutocomplete-option": {
                  p: "16px !important",
                  lineHeight: "20px !important",

                  "&:hover": {
                    bgcolor: "var(--inputs-bg) !important",
                  },
                },
              }}
            >
              {children}
            </Paper>
          )}
          sx={{
            width: "100%",

            "& .MuiOutlinedInput-root": {
              border: "none",
              p: 0,
              pl: 1,
            },
          }}
          renderInput={(params) => (
            <TextField
              placeholder="Город, радиус."
              autoFocus={false}
              focused={false}
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: <SearchIcon stroke="var(--secondary-text)" />,
              }}
              sx={{
                outline: "none",
                border: "none",
                borderRadius: "8px",
                fontFamily: "Roboto, sans-serif",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "16px",
                letterSpacing: "1px",
                background: "var(--inputs-bg)",

                "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
                  color: "var(--secondary-text)",
                  p: "6px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },

                ".MuiAutocomplete-clearIndicator": {
                  color: "var(--secondary-text)",
                },
              }}
            />
          )}
        />
      </div>
    </div>
  )
}

export default FilterByCity
