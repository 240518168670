import { FC, useState } from "react"
import { useAppDispatch } from "../../../app/hooks"
import { hide } from "../SignInSlice"
import TabLayout from "../../../components/tabs/TabsLayout"
import LoginForm from "../../../components/login_form/LoginForm"
import RegistrationForm from "../../../components/registartion_form/LoginForm"
import ResetForm from "../../../components/reset_form"
import style from "./style.module.scss"

const LayoutSignDialog: FC = () => {
  const [isReset, setIsReset] = useState<boolean | string>(false)
  const [email, setEmail] = useState("")
  const [errorText, setErrorText] = useState<string | null>(null)
  const dispatch = useAppDispatch()

  if (isReset) {
    return (
      <ResetForm
        login={email}
        back={() => {
          setIsReset(false)
          dispatch(hide())
        }}
      />
    )
  }
  return (
    <>
      <div className={style.modal_header}>
        <h2 className={style.modal_title}>Привет!</h2>
      </div>
      <div className={style.modal_content}>
        <TabLayout
          tabs={[
            {
              title: "Вход",
              content: (
                <LoginForm
                  reset={(email: string) => {
                    setEmail(email)
                    setIsReset(true)
                  }}
                  handleErrorText={(errorText) => setErrorText(errorText)}
                  lock={false}
                />
              ),
            },
            {
              title: "Регистрация",
              content: (
                <RegistrationForm
                  userName={""}
                  email={""}
                  password={""}
                  rePassword={""}
                />
              ),
            },
          ]}
        />
        <div className={style.modal_error}>{errorText ? errorText : ""}</div>
      </div>
    </>
  )
}
export default LayoutSignDialog

/*<SocialLinks />*/
