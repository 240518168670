import { useState, useRef, useEffect, KeyboardEvent, FC } from "react"
import s from "./styles.module.css"

interface CodeInputProps {
  handleChange: (param: string) => void
  clearError: () => void
  errorText: string | null
}
const CodeInputs: FC<CodeInputProps> = ({
  handleChange,
  clearError,
  errorText,
}) => {
  const [code, setCode] = useState<string[]>(["", "", "", ""])
  const [disabled, setDisabled] = useState(false)
  const inputRefs = [
    useRef<HTMLInputElement | null>(null),
    useRef<HTMLInputElement | null>(null),
    useRef<HTMLInputElement | null>(null),
    useRef<HTMLInputElement | null>(null),
  ]

  const handleKeyPress = (
    index: number,
    e: KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.key === "Enter") return
    if (e.key.length !== 1 && e.key !== "Backspace") return

    setCode((prevCode) => {
      const newCode = [...prevCode]
      newCode[index] = e.key !== "Backspace" ? e.key : ""
      return newCode
    })

    // Если не последний блок и существует inputRefs[index + 1], переключаем фокус на следующий блок
    if (index < 3 && inputRefs[index + 1]?.current && e.key !== "Backspace") {
      inputRefs[index + 1].current!.focus()
    }

    if (e.key === "Backspace") {
      // Если не первый блок и существует inputRefs[index - 1], переключаем фокус на предыдущий блок
      // При удалении данный фрагмент кода скипает фокус инпута на шаг назад
      if (index === 0) {
        return
      } else if (index > 0 && inputRefs[index - 1]?.current && code[index]) {
        setCode((prevCode) => {
          const newCode = [...prevCode]
          newCode[index] = ""
          return newCode
        })
      } else {
        inputRefs[index - 1].current!.focus()
      }
    }
  }

  useEffect(() => {
    //при любом изменение стейта мы будем чистить errorText
    if (errorText) {
      clearError()
    }
    // Ожидание 500 миллисекунд перед выполнением действий с введенным кодом
    const timeoutId = setTimeout(() => {
      const fullCode = code.join("").trim()

      if (fullCode.length === 4) {
        setDisabled(true)
        setTimeout(() => {
          handleChange(fullCode)
          setDisabled(false)
        }, 1000)
      }
    }, 500)

    // Очистка таймаута при каждом изменении кода
    return () => clearTimeout(timeoutId)
  }, [code])

  return (
    <>
      <div className={s.code_input}>
        {code.map((value, index) => (
          <input
            disabled={disabled}
            className={s.input}
            key={index}
            type="text"
            maxLength={1}
            value={value}
            onKeyDown={(e) => handleKeyPress(index, e)}
            onChange={() => {}}
            ref={inputRefs[index]}
          />
        ))}
      </div>
      <p className={s.errorText}>{errorText ? errorText : ""}</p>
    </>
  )
}

export default CodeInputs
