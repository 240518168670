import React, { FC } from "react";
import { Box } from "@mui/material"
import { CategoryInfo } from "../../filtered/types"
import { categoryTab } from "../categoryTabsStyles"
import style from "../styles.module.css"

export type CategoryItemProps = {
  category: CategoryInfo
  click: (value: number) => void
  isSelected: boolean
}
const filterStyle = (isSelected: boolean):  React.CSSProperties => {
  return  {
    filter: `${isSelected? "none" : "grayscale(100%)"}`,
  }
}

const CategoryItem: FC<CategoryItemProps> = (props) => {
  const { category: cat, isSelected } = props
  const clickHandler = () => {
    props.click(cat.id);
  }

  return (
    <Box
      onClick={clickHandler}
      sx={categoryTab}
      style={filterStyle(isSelected)}
    >
      <picture>
        <img
          className={style.category_card}
          src={cat.url}
          srcSet={cat.srcset}
          alt={cat.title}
        />
      </picture>
    </Box>
  )
}
export default CategoryItem
