import { useEffect, useState } from "react"
import { Box } from "@mui/material"

import { useAppDispatch } from "../../../../app/hooks"
import { useIsAuthorizedUser } from "../../../../hooks/useIsAuthorizedUser"
import { show } from "../../../../pages/signIn/SignInSlice"
import HeaderIconBtn from "../HeaderIconsWrapper"
import { HeaderBtnName } from "../HeaderIconsWrapper/enum"
import HeaderProfileMenu from "../HeaderProfileMenu"
import avatar from "/src/assets/svg/header-icons-pack/avatar-empty-icon.svg"

export const HeaderIcons = () => {
  const isUserAuth = useIsAuthorizedUser()
  const dispatch = useAppDispatch()

  const [activeBtn, setActiveBtn] = useState<HeaderBtnName | null>(null)
  const [isOpenMenu, setIsOpenMenu] = useState(false)

  const ls = localStorage.getItem("LocalCurrentUser")
  const isOrganiazerRole = ls
    ? JSON.parse(atob(ls.split(".")[1]))?.role === "ORGANIZATION_ADMIN"
    : false
  // Для удобства тестироввания, убрать 15/10/2024
  console.log(
    "роль:",
    ls ? JSON.parse(atob(ls.split(".")[1])).role : "нет роли",
  )
  const handleProfileMenuOpen = (): void => {
    if (!isUserAuth) {
      dispatch(show())
    } else {
      setIsOpenMenu(!isOpenMenu)
      setActiveBtn(null)
    }
  }

  useEffect(() => {
    if (isOpenMenu) {
      document.body.style.overflow = "hidden"
      document.body.style.height = "100vh"
    } else {
      document.body.style.overflow = "auto"
      document.body.style.height = "auto"
    }
    return () => {
      document.body.style.overflow = "auto"
      document.body.style.height = "auto"
    }
  }, [isOpenMenu])

  return (
    <Box onClick={handleProfileMenuOpen}>
      <HeaderIconBtn
        icon={<img src={avatar} alt="profile icon" />}
        setActiveBtn={setActiveBtn}
        activeBtn={activeBtn}
        title={HeaderBtnName.profile}
      />
      <HeaderProfileMenu
        isOpenMenu={isOpenMenu}
        isOrganiazerRole={isOrganiazerRole}
      />
    </Box>
  )
}
