import { Outlet } from "react-router-dom"
import Box from "@mui/material/Box"

import ProfileSettingsTab from "../../../components/setting_profile_tabs"
import { settingsCont } from "./indexStyles"
import s from "./styles.module.css"

const ProfileSettings = () => {
  return (
    <div className={s.main}>
      <Box sx={settingsCont}>
        <ProfileSettingsTab />
        <Outlet />
      </Box>
    </div>
  )
}

export default ProfileSettings
