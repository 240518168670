import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { EventModel } from "../../../types"
import { InvitedData, PootentionalParticipantByIdResp, PotentionalParticipantById } from "../promotion/promotionApi"

export type UserDataStore = {
  subscribeData: UserSubscription
  info: UserInfo & {
    isPromoter_or_Cashier: boolean} 
  myEvents: EventModel[]
  invites: EventModel[]
  iWillGo: EventModel[]
  Promotion: EventModel[]
  potentionalParticipants: InvitedData<PotentionalParticipantById[] > | null
  
}


export type UserSubscription = {
  subscription: number
  subscribers: number
}
 export type UserInfo = {
   age: number | null
   avatar_url: string | null
   full_name: string
   username: string
   id: number | null
 }
const initialState: UserDataStore = {
  subscribeData: {
    subscription: 0,
    subscribers: 0,
  },
  info: {
    isPromoter_or_Cashier: false,
    age: null,
    avatar_url: null,
    full_name: "",
    username: "",
    id: null
  },
  myEvents: [],
  invites: [],
  iWillGo: [],
  Promotion: [],
  potentionalParticipants: null
}

export const userDataSlice = createSlice({
  name: "USER-DATA",
  initialState: initialState,
  reducers: {
    setSubscribeData: (
      state,
      action: PayloadAction<Pick<UserDataStore, "subscribeData">>,
    ) => {
      state.subscribeData = action.payload.subscribeData
    },
    setMyEvents: (state, action: PayloadAction<EventModel[]>) => {
      state.myEvents = action.payload
    },
    setInvites: (state, action: PayloadAction<EventModel[]>) => {
      state.invites = action.payload
    },
    setIWillGo: (state, action: PayloadAction<EventModel[]>) => {
      state.iWillGo = action.payload
    },
    setTribrePromotion: (state, action: PayloadAction<EventModel[]>) => {
      state.Promotion = action.payload
    },
    resetProfile: _ => {
      return  initialState
    },
    setProfileInfo: (state, action: PayloadAction<UserDataStore['info']>) => {
      state.info = action.payload
    },
    resetPotentialParticipantsList: (state, /* action: PayloadAction<InvitedData<PootentionalParticipantByIdResp[] > | null> */) => {
      state.potentionalParticipants = null
    }
  },
})

export default userDataSlice.reducer

export const { setProfileInfo ,resetProfile, setSubscribeData, setMyEvents, setInvites, setIWillGo, setTribrePromotion, resetPotentialParticipantsList } =
  userDataSlice.actions
