import { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { setPromotionPopup } from "../../../app/data/popupSlice/popupSlice"
import {
  CreateTicketForParticipantData,
  useAddNewPotentialParticipant,
  useCreateTicketForParticipant,
  useDeleteParticipantMutation,
  usePathParticipant,
  UserDataForAddToBaseParticipant,
  UserDataForPatchToBaseParticipant,
} from "../../../app/data/promotion/promotionApi"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import BtnClose from "../../../assets/svg/close.svg?react"
import LocationSpinner from "../../profile_settings/location/location_spinner"
import s from "./styles.module.scss"
import SubModalMessage, { SubModalMessages } from "./sub_modal_message"
import AddNewUser from "./views/add_new_user"
import AddUserAccess from "./views/add_user_access"
import SearchUser from "./views/search_user"
import SendQr, { Messengers } from "./views/send_qr"

export type PotentiaParticipantUserPreData = {
  firstname: string
  lastname: string
  phone_number: string
  telegram_username: string
}

export enum ViewsNames {
  SendQr = "SendQr",
  SearchUser = "SearchUser",
  AddNewUser = "AddNewUser",
  AccessNewUser = "AccessNewUser",
}

const AddToBaseModalTitles = {
  SendQr: "Отправить QR-код",
  AddNewUser: "Добавить человека в базу",
  SearchUser: "Поиск участников на:",
  AccessNewUser: "Подтвердите добавление",
  Edit: "Редактирование участника",
}

export type SendWhatsAppData = {
  isSend?: boolean
  ticket?: string
}

const AddToBaseModal = () => {
  const currentEventData = useAppSelector(
    (state) => state.popupsState.promotionPopup,
  )
  const [isChanged, setChanged] = useState(false) // отслеживание изменений в полях редактирования участника, если изминения были - разблокировать кнопку "далее"
  const [sendToWatsapp, setSendToWatsapp] = useState<SendWhatsAppData>({isSend: false, ticket: ''})
  const [page, setPage] = useState(0)
  const participantList = useRef<HTMLUListElement | null>(null)
  const dispatch = useAppDispatch()
  const [updateParticipant, { error: patchError, isSuccess: patchSuccess, reset: patchReset }] =
    usePathParticipant()
  const [deleteParticipant, { isSuccess: deleteParticipantSuccess }] =
    useDeleteParticipantMutation()
  const [currentSubMessage, setCurrentSubMessage] = useState<
    keyof typeof SubModalMessages | null
  >(null)
  const [subIsVisible, setSubIsVisible] = useState(false)
  const [scrolled, setScrolled] = useState(0)
  const [backFromQr, setBackFromQr] = useState<ViewsNames>(
    ViewsNames.AddNewUser,
  )
  const [
    addParticipant,
    {
      isSuccess: addedPaticipantSuccess,
      isLoading: addedPaticipantLoad,
      data: addedPaticipantId,
      error: addedPaticipantError,
      reset: resetAddSucces,
    },
  ] = useAddNewPotentialParticipant()
  const [
    getTicketUrl,
    {
      isSuccess: ticketSuccess,
      isLoading: ticketIsLoad,
      data: ticketUrl,
      isError: ticketError,
      reset: resetTicketFlags,
      error: ticketErrorStatus,
    },
  ] = useCreateTicketForParticipant()
  const [view, setView] = useState<ViewsNames>(ViewsNames.SearchUser)
  const [isEdit, setEdit] = useState(false) // поле добавления нового, становиться полем редактирования
  const [addedUser, setAddedUser] = useState<UserDataForAddToBaseParticipant>({
    firstname: "",
    lastname: "",
    phone_number: "",
    telegram_username: "",
    event_id: currentEventData?.id as number,
    ticket_url: "",
    ticket_id: 0,
    potential_participant_id: 0,
    invited_via_telegram: false,
    invited_via_whatsapp: false,
  })
  const [messenger, setMessenger] = useState<Messengers | null>(null)

  useEffect(() => {
    if (view === ViewsNames.SearchUser) {
      reloadAddForm()
      setEdit(false)
      setMessenger(null)
      setChanged(false)
    }
    if (view !== ViewsNames.SearchUser) {
      setPage(0)
      setScrolled(0)
    }
  
  }, [view])

  useEffect(() => {
    if (
      (addedPaticipantError as FetchBaseQueryError)?.status === 409 ||
      (patchError as FetchBaseQueryError)?.status === 409
    ) {
      onClickOpenSubmodal("addNew")
    }

    if ((ticketErrorStatus as FetchBaseQueryError)?.status === 409) {
      console.log("ticket error")
      onClickOpenSubmodal("createTicket")
      handleSetView(ViewsNames.SearchUser)
    }

    if (patchSuccess) {
      handleSetView(ViewsNames.SearchUser)
      setEdit(false)
    }

    if (deleteParticipantSuccess) {
      setSubIsVisible(!subIsVisible)
      handleSetView(ViewsNames.SearchUser)
    }
  }, [
    addedPaticipantError,
    deleteParticipantSuccess,
    patchError,
    ticketErrorStatus,
  ])

  const onClickOpenSubmodal = (currMessage: keyof typeof SubModalMessages) => {
    setCurrentSubMessage(currMessage)
    setSubIsVisible(!subIsVisible)
  }

  useEffect(() => {
    ticketSuccess && setAddedUser({ ...addedUser, ticket_url: ticketUrl })
    resetTicketFlags()
  }, [ticketSuccess])

  const handleCreateTicket = (data: CreateTicketForParticipantData) => {
    getTicketUrl(data)
  }

  const reloadAddForm = () => {
    setAddedUser({
      ...addedUser,
      firstname: "",
      lastname: "",
      phone_number: "",
      telegram_username: "",
      event_id: currentEventData?.id as number,
      ticket_url: "",
      ticket_id: 0,
      potential_participant_id: 0,
    })
  }

  const onSetBackFromQr = (view: ViewsNames) => {
    setBackFromQr(view)
  }

  const handleDeleteParticipant = () => {
    addedUser.potential_participant_id &&
      deleteParticipant({
        event_id: addedUser.event_id,
        potential_participant_id: addedUser.potential_participant_id,
      })
  }
  const onSetAddedUser = (data: UserDataForAddToBaseParticipant) => {
    setAddedUser(data)
    handleSetView(ViewsNames.SendQr)
  }

  const handleSetAddedUser = (
    currentKey: keyof PotentiaParticipantUserPreData,
    targetValue: string,
  ) => setAddedUser({ ...addedUser, [currentKey]: targetValue })

  const handleSetView = (typeofView: ViewsNames) => {
    setView(typeofView)
  }

  const onSubmitAddParticipant = (
    participantData: Omit<
      UserDataForAddToBaseParticipant,
      | "ticket_url"
      | "ticket_id"
      | "invited_via_whatsapp"
      | "invited_via_telegram"
    >,
  ) => {
    const dataForPatch: UserDataForPatchToBaseParticipant = {
      firstname: participantData.firstname,
      lastname: participantData.lastname,
      phone_number: participantData.phone_number,
      telegram_username: participantData.telegram_username,
      potential_participant_id: participantData.potential_participant_id,
    }

    const addData: Omit<
      UserDataForAddToBaseParticipant,
      | "ticket_url"
      | "ticket_id"
      | "potential_participant_id"
      | "invited_via_telegram"
      | "invited_via_whatsapp"
    > = {
      firstname: participantData.firstname,
      lastname: participantData.lastname,
      phone_number: participantData.phone_number,
      telegram_username: participantData.telegram_username,
      event_id: participantData.event_id,
    }

    if (!isEdit) {
      addParticipant({
        ...addData,
        phone_number: addedUser.phone_number,
      })
    }
    if (isEdit) {
      updateParticipant({
        ...dataForPatch,
        phone_number: addedUser.phone_number,
      })
    }
    setAddedUser({ ...addedUser, event_id: currentEventData?.id as number })
  }

  const renderCurrentView = () =>
    view === ViewsNames.SearchUser ? (
      <SearchUser
        onOpenSubmodal={onClickOpenSubmodal}
        scrolled={scrolled}
        setScrolled={setScrolled}
        participantUlList={participantList}
        currePage={page}
        onSetPage={setPage}
        onSetCurrentUser={onSetAddedUser}
        onClicksetBackView={onSetBackFromQr}
        eventId={currentEventData?.id as number}
        onSetVeiew={handleSetView}
      />
    ) : view === ViewsNames.AddNewUser ? (
      <AddNewUser
        isChanged= {isChanged}
        setChanged={setChanged}
        onModalDeleteParticipant={onClickOpenSubmodal}
        isEdit={isEdit}
        onChangeSetPhone={writePhoneNumber}
        userData={addedUser}
        onWriteUserData={handleSetAddedUser}
        onSetVeiew={handleSetView}
      />
    ) : view === ViewsNames.SendQr && !ticketError ? (
      <SendQr
        setSendToWhatsApp={setSendToWatsapp}
        sendToWhatsApp={sendToWatsapp}
        event_date_day={currentEventData?.start_time as string}
        onSetMessanger={setMessenger}
        messenger={messenger}
        onOpenSubModal={onClickOpenSubmodal}
        ticketSuccess={ticketSuccess}
        onCteateTicket={handleCreateTicket}
        onSetEdit={setEdit}
        createdTicket={ticketUrl as string}
        userData={addedUser}
        onSetView={handleSetView}
      />
    ) : (
      <AddUserAccess
        patchSuccess={patchSuccess}
        resetPatch={patchReset}
        resetAddSuccess={resetAddSucces}
        addParticipantSuccess={addedPaticipantSuccess}
        addParticipantErrorStatus={0}
        onAddParticipant={onSubmitAddParticipant}
        onSetView={handleSetView}
        userData={{ ...addedUser, event_id: currentEventData?.id as number }}
      />
    )

  const checkIsLoading = () =>
    addedPaticipantLoad /* || ticketIsLoad */ ? (
      <LocationSpinner />
    ) : (
      renderCurrentView()
    )

  const writePhoneNumber = (phoneNumber: string) => {
    setAddedUser({ ...addedUser, phone_number: phoneNumber })
  }

  const renderSubtitle = () =>
    view === "SearchUser"
      ? currentEventData?.event_name
      : view === "AddNewUser"
      ? "Введите данные"
      : null

  const handleClosedPopup = () => {
    participantList.current?.scroll({
      top: 0,
      behavior: "instant",
    })
    setChanged(false)
    setPage(0)
    setEdit(false)
    dispatch(setPromotionPopup(null))
    setView(ViewsNames.SearchUser)
    unlockBodyScroll()
  }

  const unlockBodyScroll = () => {
    document.body.style.overflow = "auto"
    document.body.style.height = "auto"
  }

  return createPortal(
    <div
      className={s.modal_overlay}
      style={{ display: currentEventData ? "flex" : "none" }}
    >
      <div className={s.modal_body}>
        <BtnClose
          className={s.modal_close}
          onClick={() => handleClosedPopup()}
        />
        <div className={s.modal_guts}>
          <div className={s.modal_header}>
            <h2 className={s.modal_header_title}>
              {isEdit
                ? AddToBaseModalTitles["Edit"]
                : AddToBaseModalTitles[view]}
            </h2>
            {renderSubtitle() && (
              <div className={s.modal_header_subtitle}>{renderSubtitle()}</div>
            )}
          </div>
          <div className={s.modal_content}>{checkIsLoading()}</div>
        </div>
      </div>
      <SubModalMessage
        ticket={sendToWatsapp.ticket ?? ''}
        setSendToWhatsApp={setSendToWatsapp}
        onSetMessangerForSendTicket={setMessenger}
        messanger={messenger}
        participantName={`${addedUser.firstname} ${addedUser.lastname}`}
        onDeleteParticipant={handleDeleteParticipant}
        onBackToView={handleSetView}
        currenSubMessage={currentSubMessage ?? "addNew"}
        setIsVisible={setSubIsVisible}
        isVisible={subIsVisible}
      />
    </div>,
    document.body,
  )
}

export default AddToBaseModal
